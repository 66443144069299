import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import DropDown1 from "../components/Dropdown1/DropDown1";
import mobilebg from "../images/tab_bg_2.png";
import food2 from "../images/Food_2.png";
import "../scss/global.scss";
import Helmet from "react-helmet";
const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`Tyson APAC Landing`}
      />
      <div className="landingPage">
        <div className="landingContent">
          <div className="logo-section">
            <h2>WELCOME TO</h2>
            <StaticImage
              src="../images/tyson_logo.png"
              className="logo-img"
              alt="First Pride"
            />
          </div>
          <div className="selectCountry">
            <DropDown1 />
          </div>
        </div>
        <StaticImage src="../images/Food_1.png" className="food1" alt="food1" />
        <div className="food2Div">
          <img src={food2} className="food2" alt="food2" />
        </div>
        <StaticImage
          src="../images/red_tab_2.png"
          className="red_tab"
          alt="red tab"
        />
        <div className="red-tabs">
          <img src={mobilebg} className="red_tab_mobile" alt="red tab" />
        </div>
      </div>
    </>
  );
};

export default IndexPage;
