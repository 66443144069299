import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import th from "../../images/icons/icon_th.png";
import sg from "../../images/icons/icon_sg.png";
import ma from "../../images/icons/icon_ma.png";
import "./DropDown1.scss";
const Main = styled("div")``;

const DropDownContainer = styled("div")`
  width: fit-content;
  margin: 0 auto;
  background-color: #ffffff7d;
  border-radius: 7px;
`;

const DropDownHeader = styled.button`
  margin-bottom: 0em;
  outline: none;
  border: none;
  padding: 15px 50px;
  color: #252525;
  box-shadow: ${({ show }) => (show ? "" : "0 3px 3px #00000080")};
  border-radius: ${({ show }) => (show ? "6px 6px 0 0" : "6px")};
  text-transform: uppercase;
  font-size: 1.667vw;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    font-size: 2.558vw;
  }
  @media screen and (max-width: 630px) {
    font-size: 4.5vw;
    padding: 15px 20px;
  }
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background-color: "red";
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: #3faffa;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
  margin-top: 0.8em;
  @media screen and (max-width: 550px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const DropDown1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  return (
    <Main>
      <DropDownContainer>
        <DropDownHeader onClick={toggling} show={isOpen}>
          <div className="textt">
            Please Select Your Country <FaChevronDown className="downArrow" />
          </div>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem>
                <a href="https://tyson.co.th/">
                  <div className="cList">
                    <div className="cImg">
                      <img src={th} alt="thailand" />
                    </div>
                    <div className="cName">THAILAND</div>
                  </div>
                </a>
              </ListItem>
              <ListItem>
                <a href="https://www.tyson.sg/">
                  <div className="cList">
                    <div className="cImg">
                      <img src={sg} alt="thailand" />
                    </div>
                    <div className="cName">SINGAPORE</div>
                  </div>
                </a>
              </ListItem>
             <ListItem>
                <a href="https://www.tyson.com.my/">
                  <div className="cList">
                    <div className="cImg">
                      <img src={ma} alt="thailand" />
                    </div>
                    <div className="cName">MALAYSIA</div>
                  </div>
                </a>
              </ListItem>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};
export default DropDown1;
